import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide,nextTick} from 'vue';
import ZyfwzListUtil,{IZyfwzListDataObj} from './zyfwzListUtil';
export default defineComponent ({
    name: 'ZyfwzList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IZyfwzListDataObj=reactive<IZyfwzListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            importGuidParams:{
                ownerInst:proxy,
                uploadParams:{
                    saveType:0,
                    action:utils.ToolsProviderApi.buildUrl('/zyfwz/uploadFiles')
                },
                downloadParams:{
                    sourceName:'自营服务站代码上传数据表模板.xlsx',
                    downLoadFileName:'自营服务站代码上传数据表模板.xlsx',
                    action:utils.Api.buildUrl('/zyfwz/downLoadResourceFile')
                }
            },
            pageList: {
                isShowFixCol:false,
                queryParam: {},
                modelMethod: utils.ToolsProviderApi.buildUrl('/zyfwz/pageData')
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new ZyfwzListUtil(proxy,dataObj);
        })
        onMounted(()=>{
        })
        //导入按钮事件
        const importHandler=()=>{
            dataObj.refMap.get('importGuid').importDataDialogVisible=true
        }

        return{
            ...toRefs(dataObj),importHandler
        }
    }
});